.link {
    @apply text-gray-600 font-medium relative inline-block border-b border-transparent hover:border-gray-300
}

.linkTitle {
    @apply font-semibold text-gray-800
}

.wrapper svg {
    height: 200px;
    width: 200px;
}

.wrapper rect {
    fill: aqua;
    height: 190px;
    stroke: darkmagenta;
    width: 190px;
}