.card-hover {
    @apply text-center transition ease-in-out bg-white rounded-3xl border-slate-100 shadow-sm hover:shadow-xl hover:-translate-y-2.5
}

.card {
    @apply transition ease-in-out bg-white rounded-3xl shadow-sm  border-transparent  border  hover:border-opacity-50  hover:border-slate-200
}

.app-icon-40 {
    @apply h-40 w-40 rounded-3xl border mt-16
}

.app-icon-20 {
    @apply h-20 w-20 rounded-3xl border mt-10
}

.background-primary {
    @apply bg-slate-50
}

.background-secondary {
    @apply bg-slate-100
}
